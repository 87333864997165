.first-screen {
  position: relative;
  margin-top: -1px;
  &__dots {
    position: absolute;
    top: 82px;
    left: 0;
    z-index: 5;
    ul {
      display: flex;
      justify-content: flex-start;
      padding-left: 17px;
    }
    li {
      color: #fff;
      list-style: none;
      outline: inherit;
      margin-left: 0;
      margin-right: 19px;
    }
    button {
      font-size: 0;
      line-height: 0;
      width: 11px;
      height: 11px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.3);
      outline: inherit;
      border: 2px solid transparent;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
        transform: scale(1.5);
      }
    }
    .slick-active {
      button {
        border: 2px solid #75AC40;
        background-color: rgba(255, 255, 255, 0);
        transform: scale(1.2);
      }
    }
  }
  &__slide {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__block {
    min-height: calc(100vh - 135px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: 64px;
    line-height: 80px;
    font-weight: 600;
    color: #fff;
    max-width: 750px;
    margin-bottom: 36px;
  }
  &__subtitle {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    display: block;
    margin-bottom: 66px;
  }
  .block-btn {
    width: 170px;
    margin-bottom: 25px;
    &__btn {
      background-color: #75AC40;
      &:hover {
        background-color: #669836;
      }
    }
  }
  &__arrows {
    position: absolute;
    bottom: 73px;
    left: 16px;
    display: flex;
    .slick-arrow {
      font-size: 0;
      line-height: 0;
      width: 60px;
      height: 60px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
      position: relative;
      outline: inherit;
      transition: all 0.3s;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 19px;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -9.5px;
        background-repeat: no-repeat;
        background-image: url(../img/arrow-next.svg);
        background-size: contain;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
        transform: scale(1.2);
        &::before {
          transform: scale(0.86);
        }
      }
    }
    .slick-prev {
      margin-right: 18px;
      &::before {
        margin-left: -7px;
        background-image: url(../img/arrow-prev.svg);
      }
    }
  }
}
.main-one {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 22px;
  }
  &__caption {
    font-size: 30px;
    line-height: 38px;
  }
  &__link {
    font-size: 18px;
    line-height: 23px;
    color: #FF4B4B;
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #D53838;
    }
    &:focus {
      color: #D53838;
    }
  }
}
.one-slider {
  position: relative;
  .block-name {
    right: auto;
    left: 40px;
  }
  &__slide {
    height: 515px;
    padding: 26px 40px 40px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  &__block {
    display: flex;
    justify-content: flex-end;
    align-content: space-between;
    flex-wrap: wrap;
    height: 100%;
  }
  &__title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;
    width: 100%;
    text-align: right;
  }
  &__subtitle {
    display: block;
    max-width: 380px;
    text-align: right;
    width: 100%;
    margin-top: -23px;
  }
  &__price {
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    width: 100%;
    margin-top: 20px;
    b {
      font-size: 72px;
      line-height: 91px;
      color: #F45050;
      font-weight: 400;
      margin-right: 2px;
    }
  }
  &__wrap {
    width: 100%;
    margin-bottom: 20px;
  }
  &__sm-price {
    font-size: 18px;
    line-height: 23px;
    display: block;
    text-align: right;
    b {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
      margin-right: 2px;
    }
  }
  .block-btn {
    width: 170px;
    &__btn {
      background-color: #75AC40;
      &:hover {
        background-color: #669836;
      }
    }
  }
  .slick-arrow {
    position: absolute;
    font-size: 0;
    line-height: 0;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    z-index: 5;
    top: 50%;
    margin-top: -30px;
    outline: inherit;
    transition: all 0.3s ease-in-out;
    &::before {
      content: "";
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 19px;
      top: 50%;
      left: 50%;
      margin-top: -9.5px;
      margin-left: -6px;
    }
    &:hover {
      box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
    }
  }
  .slick-prev {
    left: -20px;
    &::before {
      background-image: url(../img/prev-black.svg);
    }
  }
  .slick-next {
    right: -20px;
    &::before {
      background-image: url(../img/next-black.svg);
    }
  }
}
.main-two {
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 80px;
  &__wrapper {
    padding: 29px 35px 40px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 430px;
  }
  &__block {
    align-self: flex-start;
    width: 100%;
    &:nth-child(3) {
      margin-top: -34px;
    }
  }
  &__title {
    font-size: 48px;
    line-height: 60px;
    max-width: 500px;
    color: #fff;
    width: 100%;
    margin-bottom: 0;
  }
  &__subtitle {
    font-size: 18px;
    line-height: 29px;
    color: #fff;
    width: 100%;
    flex-grow: 1;
    display: block;
  }
  .block-btn {
    align-self: flex-end;
    width: 170px;
    margin-left: 5px;
    margin-bottom: 2px;
    &__btn {
      background-color: #75ac40;
      &:hover {
        background-color: #669836;
      }
    }
  }
  .block-name {
    right: -3px;
    top: 24px;
  }
}
.main-three {
  &__wrapper {
    margin-top: 79px;
  }
}
.main-four {
  background-color: #F2F2F2;
  margin-top: 80px;
  padding-top: 81px;
  padding-bottom: 50px;
}
.news-block {
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  &__link {
    color: #F45050;
    text-decoration: underline;
    font-size: 18px;
    line-height: 23px;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #D53838;
      text-decoration: underline;
    }
    &:focus {
      color: #D53838;
      text-decoration: underline;
    }
  }
}

