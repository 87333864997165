.television {
  &__block-text {
    padding-left: 2px;
  }
  &__text {
    font-size: 16px;
    line-height: 26px;
    display: block;
    margin-bottom: 10px;
    a {
      color: #F45050;
      font-size: 16px;
      line-height: 26px;
      text-decoration: underline;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #d53838;
        text-decoration: underline;
      }
      &:focus {
        color: #d53838;
        text-decoration: underline;
      }
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    margin-top: 10px;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 15px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 11px;
      width: 7px;
      height: 7px;
      background-color: #F45050;
      border-radius: 100px;
    }
  }
  &__button {
    border-bottom: 2px solid #75AC40;
  }
  em {
    font-size: 16px;
    line-height: 26px;
    font-style: normal;
    background-color: #ACCD8C;
    display: inline-block;
    margin-top: 13px;
    padding: 1px 18px 3px 10px;
    margin-bottom: 28px;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }
  &__icon {
    width: 16px;
    height: 20px;
    display: block;
    background-image: url(../img/file-document-outline.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 15px;
  }
  &__receipts {
    display: block;
    font-size: 16px;
    line-height: 26px;
    position: relative;
  }
}
.disable {
  opacity: 0.4;
}
.rates {
  margin-top: 10px;
  padding-bottom: 60px;
  &--individuals {
    margin-top: 40px;
  }
  &__caption {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 22px;
    margin-bottom: 25px;
  }
  &__block {
    background-color: #fff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 27px 30px 30px;
    min-height: 456px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    .block-btn {
      position: relative;
      overflow: hidden;
      z-index: 4;
      &__btn {
        &--active {
          position: absolute;
          right: 110%;
          top: 0;
          width: 100%;
          height: 100%;
          transition: all 0.6s;
          &--open {
            right: 0;
          }
        }
      }
    }
  }
  &__block-text {
    margin-bottom: 20px;
  }
  &__name {
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    text-transform: uppercase;
    display: block;
    letter-spacing: 0.5px;
  }
  &__caption-block {
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    display: block;
    margin-top: 13px;
    margin-bottom: 25px;
  }
  &__text {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
  }
  &__price-block {
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 7px;
    //    margin-bottom: 11px;
  }
  &__price {
    font-size: 24px;
    line-height: 30px;
    word-wrap: 1px;
    b {
      font-size: 48px;
      line-height: 60px;
      color: #F45050;
      font-weight: 400;
      display: inline-block;
    }
  }
  &__quantity {
    font-size: 16px;
    line-height: 20px;
    display: block;
    margin-bottom: 2px;
    span {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__link {
    color: #F45050;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 3;
    &:hover {
      color: #d53838;
    }
    &:focus {
      color: #d53838;
    }
  }
  .block-btn {
    margin-top: 25px;
    &__btn {
      background-color: #75AC40;
      padding: 18px 0;
      &:hover {
        background-color: #669836;
      }
    }
  }
  &__wrap-hint {
    margin: 38px 0 13px;
  }
  &__hint {
    font-size: 16px;
    line-height: 26px;
    display: block;
    margin-bottom: 8px;
  }
  &__row {
    margin-bottom: 58px;
  }
  &__item {
    position: relative;
  }
}
.rates-form {
  position: absolute;
  top: 0;
  transition: left 0.6s ease-in-out, opacity 0.6s ease-in-out, z-index 1s;
  bottom: 0;
  width: 353px;
  background-color: #fff;
  padding: 27px 30px 30px 33px;
  z-index: 2;
  box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 0 5px 5px 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  &--open {
    left: 99%;
    opacity: 1;
    z-index: 5;
  }
  &__close {
    position: absolute;
    display: block;
    right: 10px;
    top: 10px;
    width: 14px;
    height: 14px;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    z-index: 10;
  }
  &__field {
    width: 290px;
    border: 1px solid #C4C4C4;
    border-radius: 2px;
    font-size: 14px;
    line-height: 23px;
    color: #4F4F4F;
    padding: 17px 19px;
    margin-bottom: 11px;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    .rates-form__field {
      width: 48%;
    }
  }
  &__policy {
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;
    display: block;
    margin-top: 16px;
    a {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
      color: #4F4F4F;
      &:hover {
        color: #4F4F4F;
      }
      &:focus {
        color: #4F4F4F;
      }
    }
  }
}
.additional-packages {
  padding-bottom: 58px;
  &__caption {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .table {
    margin-top: 21px;
    max-width: 558px;
    &__row {
      .table__right {
        text-align: left;
        padding-left: 5px;
      }
    }
  }
}
.block-tarifs {
  &:last-child {
    .rates-form {
      left: auto;
      right: 0;
      transition: right .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
      &--open {
        right: 99%;
      }
    }
  }
}
