.contacts {
  padding-bottom: 84px;
  &__list {
    padding: 0;
    margin: 0;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 25px;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    margin-bottom: 11px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 23px;
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      margin-top: -11.5px;
      background-position: center;
    }
    &--target {
      &::before {
        background-image: url(../img/location.svg);
      }
    }
    &--mail {
      &::before {
        background-image: url(../img/contact.svg);
      }
    }
    &--tel {
      &::before {
        background-image: url(../img/phone.svg);
      }
    }
  }
}

.map {
  max-width: 700px;
  height: 375px;
  margin-top: 25px;
}