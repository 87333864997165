@media(max-width: 768px) {
  .first-screen {
    &__block {
      min-height: calc(100vh - 86px);
    }
    &__dots {
      top: 32px;
    }
    &__title {
      font-size: 50px;
      line-height: 54px;
      margin-bottom: 15px;
    }
    &__subtitle {
      margin-bottom: 30px;
    }
  }
  .main-two .block-name {
    top: 10px;
    right: 15px;
  }
  .main-two__wrapper {
    padding-top: 40px;
  }
  .sm-article {
    &__wrapper {
      flex-wrap: wrap;
    }
    &__left-block {
      width: 100%;
    }
    &__right-block {
      width: 100%;
      padding: 15px;
    }
  }
  .page-footer {
    &__wrapper {
      flex-wrap: wrap;
    }
    &__left-block {
      width: 100%;
    }
    &__right-block {
      width: 100%;
      padding-left: 0;
      padding-top: 15px;
    }
    &__policy {
      text-align: center;
      margin-top: 15px;
    }
    &__block {
      width: 100%;
      text-align: center;
    }
  }
  .block-logo {
    &__img {
      margin: 0 auto;
    }
  }
  .row-down {
    &__wrapper {
      justify-content: center;
    }
    &__text {
      text-align: center;
    }
    &__link {
      text-align: center;
      margin: 0;
    }
    &__sm-text {
      text-align: center;
    }
  }
  .block-title {
    padding-bottom: 60px;
    &__title {
      font-size: 44px;
      line-height: 50px;
      margin-top: 20px;
    }
  }
  .map {
    margin-bottom: 30px;
  }
  .internal__big-img {
    min-height: 245px;
  }
  blockquote {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
    padding-left: 25px;
  }
  .repairs-two {
    .banner {
      &__block-bg {
        padding-top: 70px;
      }
      &__title {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }
  .repairs-three {
    &__wrap {
      flex-wrap: wrap;
    }
  }
}
@media(max-width: 560px) {
  .first-screen__title {
    font-size: 32px;
    line-height: 40px;
  }
  .header-up {
    &__wrapper {
      flex-wrap: wrap
    }
    &__left-block {
      width: 100%;
      padding: 10px 0 10px 50px;
    }
    &__right-block {
      width: 100%;
    }
  }
  .one-slider {
    &__block {
      padding-top: 20px;
    }
    &__title {
      font-size: 26px;
      line-height: 30px;
    }
    &__price {
      margin-top: 0;
      b {
        font-size: 50px;
        line-height: 50px;
      }
    }
    .slick-arrow {
      top: auto;
      margin-top: auto;
      bottom: -30px;
    }
    .slick-prev {
      left: 20%;
    }
    .slick-next {
      right: 20%;
    }
  }
  .main-two {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-top: 30px;
    }
  }
  .banner {
    &__block-bg {
      padding: 20px;
      padding-top: 40px;
      position: relative;
    }
    &--right-content {
      .banner {
        &__block-bg {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        &__title {
          font-size: 22px;
          line-height: 30px;
          color: #fff;
          position: relative;
          z-index: 1;
        }
        &__text-block {
          color: #fff;
          padding-left: 0;
          position: relative;
          z-index: 1;
        }
      }
    }
    &--white-text {
      .banner {
        &__block-bg {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        &__title {
          font-size: 22px;
          line-height: 30px;
          color: #fff;
          position: relative;
          z-index: 1;
        }
        &__text-block {
          color: #fff;
          padding-left: 0;
          position: relative;
          z-index: 1;
        }
      }
    }
    .block-btn {
      position: relative;
      z-index: 1;
    }
  }
  .banner--right-content .block-name {
    top: 10px;
    left: 10px;
  }
  .banner--white-text .block-name {
    top: 10px;
    right: 10px;
  }
  .big-article {
    &__wrap {
      flex-wrap: wrap;
    }
    &__date {
      margin-top: 15px;
    }
  }
  .caption {
    font-size: 26px;
    line-height: 34px;
  }
  .bread-crumbs__list {
    flex-wrap: wrap;
  }
  .internal {
    &__list {
      flex-wrap: wrap;
    }
    &__item {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .pay__img {
    width: 290px;
    height: 90px;
  }
  .repairs-one__wrap-table {
    overflow: scroll;
  }
}
