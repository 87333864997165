@media(max-width: 1400px) {
  .first-screen__arrows {
    bottom: 40px;
  }
}
@media(max-width: 1200px) {
  .nav {
    &__item {
      margin-left: 3.3%;
    }
  }
  .one-slider .block-name {
    top: 15px;
    left: 15px;
  }
  .one-slider {
    &__slide {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    &__block {
      position: relative;
      z-index: 10;
      padding-top: 25px;
    }
    &__title {
      color: #fff;
    }
    &__subtitle {
      color: #fff;
    }
    &__price {
      color: #fff;
    }
    &__sm-price {
      color: #fff;
    }
    .slick-prev {
      left: -30px;
    }
    .slick-next {
      right: -30px;
    }
  }
  .first-screen__arrows {
    bottom: 30px;
  }
  .license__block-img {
    background-position: center;
    background-size: contain;
  }
}
@media(max-width: 991px) {
  header {
    position: relative;
  }
  .header-up {
    position: relative;
    z-index: 20;
    background-color: #fff;
    &__left-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .header-mob {
    display: block;
    position: relative;
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 30;
      padding: 5px 0;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 20;
    }
  }
  .burger {
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    &__line {
      width: 100%;
      height: 2px;
      background-color: #000;
    }
  }
  .header-wrap {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: -1200px;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: 0 0 6px rgba(0, 0, 0, .25);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;
    &--open {
      left: 0;
    }
    .container {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important;
    }
    .row {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important;
    }
    .col-xl-12 {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important;
    }
  }
  .header-up {
    order: 2;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    .container {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important;
    }
    .row {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important;
    }
    .col-xl-12 {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: 100% !important;
    }
    &__wrapper {
      width: 100%;
    }
    &__left-block {
      flex-wrap: wrap;
      padding-left: 50px;
      width: 50%;
    }
    .private-office {
      padding-right: 50px;
    }
  }
  .private-office {
    padding: 18px 28px 18px 20px;
  }
  .header-page {
    width: 100%;
    order: 1;
    padding-top: 0;
    .block-logo {
      display: none;
    }
  }
  .nav {
    flex-grow: 0;
    &__list {
      flex-wrap: wrap;
    }
    &__item {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 0;
      padding-left: 50px;
      &:last-child {
        border: none;
      }
      &::before {
        display: none;
      }
      &--active {
        a {
          color: #f45050;
        }
      }
      &--dropdown {
        a {
          position: relative;
          display: inline-block;
          padding-right: 30px;
          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 8px;
            width: 9px;
            height: 9px;
            background-image: url(../img/arrow-down.svg);
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &--open {
        .nav__sub-list {
          height: 108px;
        }
        a {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
    &__sub-list {
      position: static;
      opacity: 1;
      box-shadow: none;
      padding: 0;
      max-height: 999999999999px;
      transition: 0;
      height: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }
    &__sub-item {
      padding-left: 15px;
      padding-top: 2px;
      padding-bottom: 2px;
      a {
        &::after {
          display: none;
        }
      }
    }
  }
  .block-form {
    margin-bottom: 30px;
  }
  .banner--right-content {
    .banner {
      &__title {
        max-width: 455px;
      }
      &__text-block {
        padding-left: 50%;
      }
    }
  }
  .banner--white-text {
    .banner {
      &__title {
        max-width: 380px;
      }
      &__text-block {
        padding-right: 50%;
      }
    }
  }
  .page-footer {
    &__right-block {
      padding-left: 60px;
    }
    &__nav {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    &__block {
      width: 150px;
      margin-bottom: 15px;
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .row-down {
    &__wrapper {
      flex-wrap: wrap;
      align-items: center;
    }
    &__block {
      order: 2;
      flex-wrap: wrap;
      margin: 0;
    }
    &__text {
      width: 100%;
      display: block;
      text-align: right;
    }
    &__link {
      display: block;
      width: 100%;
      text-align: right;
    }
    &__sm-text {
      order: 3;
      width: 100%;
      margin-top: 10px;
    }
  }
  .television__block {
    margin-bottom: 30px;
  }
  .developers {
    order: 1;
  }
  .block-tarifs {
    margin-bottom: 30px;
    &:last-child {
      .rates-form {
        left: 0;
        right: 0;
        top: 0;
        transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
        background-color: #fff;
        min-height: 450px;
        width: 100%;
        &--open {
          top: 100%;
          opacity: 1;
        }
      }
    }
  }
  .rates-form {
    left: 0;
    right: 0;
    top: 0;
    transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
    background-color: #fff;
    min-height: 450px;
    width: 100%;
    &--open {
      top: 100%;
      opacity: 1;
    }
    &__field {
      width: 100%;
    }
  }
  .additional-packages {
    overflow: scroll;
  }
  .table {
    width: 500px;
  }
  .info__wrap {
    flex-wrap: wrap;
  }
  .block-wrap__block {
    margin-bottom: 30px;
  }
  .internal__title {
    font-size: 30px;
    line-height: 40px;
  }
  .repairs-one .block-form {
    margin-top: 30px;
  }
}
