.pay {
  &__container {
    margin-bottom: 75px;
  }
  &__block-img {
    margin-bottom: 29px;
  }
  &__list {
    padding: 0;
    margin: 0;
    margin-bottom: 22px;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 11px;
      width: 7px;
      height: 7px;
      background-color: #F45050;
      border-radius: 100px;
    }
  }
  &__text {
    a {
      color: #f45050;
      text-decoration: underline;
      margin-left: 1px;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #d53838;
      }
      &:focus {
        color: #d53838;
      }
    }
  }
}