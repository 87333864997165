.block-title {
  background-repeat: no-repeat;
  background-size: contain;
  padding: 37px 0 98px;
  background-size: cover;
  &__title {
    font-size: 64px;
    line-height: 80px;
    color: #fff;
    font-weight: 600;
    margin-top: 53px;
  }
  &__sub-title {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    padding-left: 6px;
    margin-top: 18px;
    display: block;
  }
}
.repairs-one {
  padding-bottom: 40px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__left-block {
    width: 65%;
  }
  &__right-block {
    width: 31.5%;
  }
}
.table {
  max-width: 600px;
  margin-top: 50px;
  &__up {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #75AC40;
    padding: 0 45px 2px 30px;
  }
  &__left {
    flex-grow: 0;
    min-width: 145px;
  }
  &__center {
    flex-grow: 1;
    text-align: left;
    padding-left: 27px;
  }
  &__right {
    flex-grow: 0;
    text-align: right;
    min-width: 111px;
  }
  &__caption {
    font-size: 18px;
    line-height: 29px;
  }
  &__wrap {
    margin-top: 17px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 0 51px 0 27px;
    margin-bottom: 10px;
  }
}
.check-button {
  margin-top: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  &__text {
    font-size: 14px;
    line-height: 23px;
    display: block;
    color: #fff;
  }
  &__block {
    margin-bottom: 0;
  }
  &__wrap {
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #F4F4F4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    width: 40px;
    height: 20px;
    display: flex;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  &__check {
    display: none;
  }
  &__circle {
    background-color: #F45050;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    left: 0;
  }
}
.check-button__check:checked + .check-button__wrap {
  .check-button__circle {
    left: 20px;
  }
}
.repairs-two {
  padding-top: 42px;
  &__caption {
    font-size: 30px;
    line-height: 38px;
    margin-top: 81px;
    padding-left: 2px;
    letter-spacing: 0.1px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
  }
  &__block {
    max-width: 370px;
  }
  &__wrap {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
  &__block-img {
    padding-left: 6px;
    margin-bottom: 0;
    padding-top: 7px;
    margin-right: 24px;
  }
  &__img {
    width: 41px;
    height: 40px;
  }
  &__sm-caption {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 29px;
    max-width: 220px;
  }
}
.repairs-three {
  margin-bottom: 77px;
  background-image: url(../img/repairs-three-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 55px;
  padding-top: 38px;
  padding-bottom: 37px;
  &__title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;
    color: #fff;
    padding-left: 1px;
    margin-bottom: 25px;
  }
  &__sub-title {
    font-size: 18px;
    line-height: 29px;
    color: #fff;
    padding-left: 1px;
  }
  &__wrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
  }
  &__field {
    width: 291px;
    font-size: 14px;
    line-height: 23px;
    padding: 0 19px;
    margin-bottom: 10px;
    height: 60px;
    transition: all 0.3s ease-in-out;
    margin-right: 31px;
  }
  .block-btn {
    width: 170px;
  }
  &__policy {
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-top: 10px;
    a {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
      &:focus {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
