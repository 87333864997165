.header-up {
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  z-index: 12;
  background-color: #fff;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    font-size: 14px;
    line-height: 18px;
    margin-right: 12px;
  }
  &__link {
    font-size: 18px;
    line-height: 23px;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}
.private-office {
  background-color: #F2F2F2;
  padding: 11px 28px 13px 20px;
  &__link {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
  &__icon {
    display: block;
    width: 24px;
    height: 16px;
    background-image: url(../img/key.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 9px;
    margin-top: 1px;
  }
  &__text {
    font-size: 16px;
    line-height: 20px;
  }
}

.header-page {
  padding: 7px 0 0;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    min-height: 83px;
  }
}

.block-logo {
  margin-bottom: 0;
  &__img {
    width: 149.89px;
    height: 76px;
  }
}

.nav {
  flex-grow: 1;
  &__list {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }
  &__sub-list {
    position: absolute;
    top: 100%;
    left: -30px;
    padding: 0;
    margin: 0;
    padding: 27px 29px 23px;
    z-index: 30;
    background-color: #fff;
    width: 273px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &__item {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 22px;
    margin-left: 5.5%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background-color: #75AC40;
      border-radius: 2px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
      .nav__sub-list {
        opacity: 1;
        z-index: 30;
      }
    }
    &--active {
      &::before {
        opacity: 1;
      }
    }
  }
  &__sub-item {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__link {
    font-size: 18px;
    line-height: 23px;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #F45050;
      text-decoration: none;
    }
    &:focus {
      color: #F45050;
      text-decoration: none;
    }
  }
}

.header-mob {
  display: none;
}