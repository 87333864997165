body,
input,
textarea,
button,
select,
p,
a,
li,
span,
cite {
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
body,
input,
textarea,
button,
select,
p,
a,
li,
span,
h1,
h2,
h3,
h4,
cite {
  font-family: 'Source Sans Pro', sans-serif;
}
blockquote {
  font-size: 24px;
  line-height: 39px;
  font-weight: 300;
  font-style: italic;
  padding-left: 50px;
  padding-right: 100px;
  margin: 27px 0;
  display: block;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    width: 16px;
    height: 16px;
    background-image: url(../img/commas.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.bread-crumbs {
  &__list {
    display: flex;
    padding: 0;
    margin: 0;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-right: 12px;
    position: relative;
    &::before {
      content: "/";
      position: absolute;
      right: 3px;
      top: 3px;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    &--black {
      &::before {
        color: #222222;
      }
    }
  }
  &__link {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
    &:focus {
      color: #fff;
      text-decoration: none;
    }
    &--black {
      color: #222222;
      &:hover {
        color: #222222;
      }
      &:focus {
        color: #222222;
      }
    }
  }
  &__text {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    &--black {
      color: #222222;
    }
  }
}
.wrap-main {
  padding-top: 80px;
}
.gray-bg {
  background-color: #F2F2F2;
  padding-top: 80px;
}
.caption {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px;
}
.text {
  line-height: 26px;
  margin-bottom: 10px;
}
.block-btn {
  &__btn {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #fff;
    background-color: #F45050;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 19px 0 20px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #D53838;
    }
  }
  a {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}
.banner {
  .block-btn {
    width: 170px;
    align-self: flex-end;
    &__btn {
      background-color: #75AC40;
      &:hover {
        background-color: #669836;
      }
    }
  }
  &--right-content {
    .banner {
      &__block-bg {
        justify-content: flex-end;
        text-align: right;
      }
      &__text-block {
        padding-right: 4px;
      }
    }
    .block-btn {
      &__btn {
        background-color: #f45050;
        &:hover {
          background-color: #d53838;
        }
      }
    }
    .block-name {
      right: auto;
      left: 40px;
    }
  }
  &--white-text {
    color: #fff;
    .banner {
      &__text-block {
        color: #fff;
      }
    }
  }
  &__block-bg {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 28px 40px 40px;
    min-height: 515px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
  }
  &__title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;
    max-width: 515px;
    margin-bottom: 0;
  }
  &__text-block {
    font-size: 18px;
    line-height: 29px;
    display: block;
    width: 100%;
    align-self: flex-start;
    margin-top: -64px;
    //    margin-bottom: 60px;
  }
}
.block-name {
  width: 167px;
  border: 1px solid #fff;
  border-radius: 2px;
  position: absolute;
  right: 33px;
  top: 40px;
  padding: 6px 0 7px;
  &__text-btn {
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    text-transform: uppercase;
    display: block;
    text-align: center;
    letter-spacing: 0.6px;
  }
}
.block-form {
  background-color: #75AC40;
  padding: 40px;
  &__title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff;
  }
  &__text {
    margin-bottom: 24px;
    color: #fff;
  }
  &__block {
    width: 100%;
  }
  &__field {
    width: 100%;
    font-size: 14px;
    line-height: 23px;
    padding: 0 19px;
    margin-bottom: 10px;
    height: 60px;
    transition: all 0.3s ease-in-out;
    &--four {
      height: 0;
      margin-bottom: 0;
    }
    &--five {
      height: 0;
      margin-bottom: 0;
    }
    &--six {
      height: 0;
      margin-bottom: 0;
    }
    &--seven {
      height: 0;
      margin-bottom: 0;
    }
    &--open {
      height: 60px;
      margin-bottom: 10px;
    }
  }
  &__textarea {
    width: 100%;
    font-size: 14px;
    line-height: 23px;
    padding: 18px 19px;
    margin-bottom: 15px;
    min-height: 110px;
    resize: none;
    transition: all 0.3s ease-in-out;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    .block-form__field {
      width: 48.5%;
    }
  }
  &__policy {
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
    display: block;
    color: #fff;
    margin-bottom: 0;
    a {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
      color: #fff;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
      &:focus {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .block-btn {
    width: 170px;
    margin-top: 18px;
    &__btn {
      background-color: #494949;
      &:hover {
        background-color: #d53838;
      }
      &:focus {
        background-color: #d53838;
      }
    }
  }
  &--contacts {
    background-color: #E2E3E1;
    .block-form {
      &__title {
        color: #222;
      }
      &__text {
        color: #222;
      }
      &__policy {
        color: #4F4F4F;
        a {
          color: #4F4F4F;
          &:hover {
            color: #4F4F4F;
          }
          &:focus {
            color: #4F4F4F;
          }
        }
      }
    }
    .block-btn {
      width: 170px;
      margin-top: 18px;
      &__btn {
        background-color: #F45050;
        &:hover {
          background-color: #d53838;
        }
        &:focus {
          background-color: #d53838;
        }
      }
    }
  }
}
.pagination {
  margin-top: 7px;
  padding-bottom: 83px;
  &__list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__arrow {
    font-size: 16px;
    line-height: 26px;
    color: #F45050;
    text-decoration: underline;
    margin: 0 9px;
    &:hover {
      color: #F45050;
      text-decoration: underline;
    }
    &:focus {
      color: #F45050;
      text-decoration: underline;
    }
  }
  &__link {
    font-size: 16px;
    line-height: 26px;
    display: block;
    width: 27px;
    height: 27px;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    text-align: center;
    &:hover {
      background-color: #E2E3E1;
      color: #F45050;
      text-decoration: none;
    }
    &:focus {
      background-color: #E2E3E1;
      color: #F45050;
      text-decoration: none;
    }
  }
}
