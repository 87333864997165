.my-modal {
  &__dialog {
    margin-top: 157px;
    max-width: 1170px;
  }
  &__content {
    border-radius: 5px;
    padding: 26px 30px 30px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  }
  &__close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 14px;
    height: 14px;
    display: block;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 20;
    cursor: pointer;
    background-color: transparent;
  }
}
.channels {
  &__text {
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 13px;
  }
  &__caption {
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
  }
  &__container {
    position: relative;
    padding-top: 7px;
  }
  &__wrap {
    height: 490px;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 0 -6.5px;
  }
  &__block {
    padding: 0 6.2px;
    margin-bottom: 10px;
  }
  &__img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 162px;
    height: 90px;
  }
}
.scrollbar-outer > .scroll-element {
  background-color: #F4F4F4;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
  background-color: #E0E0E0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  max-height: 70px;
  cursor: pointer;
}


.my-modal-two {
  .my-modal {
    &__close {
      top: 25px;
    }
    &__dialog {
      max-width: 370px;
    }
    &__name {
      font-size: 12px;
      line-height: 15px;
      color: #828282;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }
    &__caption {
      font-size: 18px;
      line-height: 23px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    &__text {
      font-size: 16px;
      line-height: 26px;
    }
    &__block-img {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}