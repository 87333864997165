.big-article {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  &__img-wrap {
    width: 100%;
    height: 265px;
    background-image: url(../img/news-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    a {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  &__block-img {
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__block-text {
    padding: 20px 30px 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__title {
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 11px;
  }
  &__text {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0;
    font-weight: 400;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .block-btn {
    width: 170px;
    margin-top: 35px;
  }
  &__date {
    font-size: 14px;
    line-height: 23px;
    color: #75ac40;
    font-weight: 600;
  }
  a {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}
.sm-article {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__left-block {
    width: 47.4%;
  }
  &__img-wrap {
    background-image: url(../img/news-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 170px;
    background-position: center;
    border-radius: 5px 0 0 5px;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__block-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    border-radius: 5px 0 0 5px;
  }
  &__right-block {
    width: 50%;
  }
  &__block-text {
    padding: 11px 5px 13px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__title {
    font-size: 14px;
    line-height: 23px;
    font-weight: 600;
  }
  &__text {
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
  }
  a {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
  &__date {
    font-size: 14px;
    line-height: 23px;
    color: #75ac40;
    font-weight: 600;
  }
}