.info {
  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
    max-width: 750px;
    margin-bottom: 47px;
  }
  &__sm-block {
    width: 185px;
  }
  &__big-number {
    font-size: 72px;
    line-height: 72px;
    color: #F45050;
    font-weight: 700;
    display: block;
  }
  &__description {
    line-height: 16px;
  }
}

.block-wrap {
  margin-top: 53px;
  &__block {
    border-radius: 5px;
  }
  &__block-img {
    min-height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 32px;
    border-radius: 5px 5px 0 0;
  }
  &__caption {
    margin-bottom: 14px;
  }
  &__text {
    line-height: 26px;
  }
  &__link {
    font-size: 18px;
    line-height: 23px;
    color: #F45050;
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
    display: block;
    margin-top: 28px;
    &:hover {
      color: #d53838;
    }
    &:focus {
      color: #d53838;
    }
  }
}

.license {
  margin-top: 75px;
  padding-bottom: 63px;
  &__wrapper {
    padding-top: 55px;
  }
  &__block {
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
  &__block-img {
    width: 100%;
    min-height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}