.page-footer {
  background-color: #98BE73;
  padding-top: 20px;
  border-bottom: 1px solid #8DAE6D;
  padding-bottom: 17px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__right-block {
    flex-grow: 1;
    padding-left: 109px;
  }
  &__policy {
    color: #222222;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    text-decoration: underline;
    display: block;
    margin-top: 35px;
    &:hover {
      color: #222222;
      text-decoration: underline;
    }
    &:focus {
      color: #222222;
      text-decoration: underline;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  &__block {
    &:last-child {
      margin-left: 39px;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 3px;
  }
  &__caption {
    color: #222222;
    display: block;
    margin-bottom: 2px;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #F2F2F2;
      text-decoration: none;
    }
    &:focus {
      color: #F2F2F2;
      text-decoration: none;
    }
  }
  &__link {
    color: #4F4F4F;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    &:hover {
      color: #F2F2F2;
      text-decoration: none;
    }
    &:focus {
      color: #F2F2F2;
      text-decoration: none;
    }
  }
}
.row-down {
  background-color: #98BE73;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px 0 20px;
  }
  &__sm-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #222222;
  }
  &__block {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
  &__text {
    color: #4F4F4F;
    font-size: 14px;
    line-height: 18px;
  }
  &__link {
    color: #4F4F4F;
    font-size: 24px;
    line-height: 30px;
    margin-left: 14px;
    &:hover {
      color: #4F4F4F;
      text-decoration: none;
    }
    &:focus {
      color: #4F4F4F;
      text-decoration: none;
    }
  }
}
.developers {
  &__link {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    &:hover {
      text-decoration: none;
    }
  }
  &__text {
    color: #222222;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    display: block;
    width: 100%;
    padding-left: 2px;
  }
  &__logo {
    width: 100px;
    margin-left: 14px;
    height: 24px;
  }
}
