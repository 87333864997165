.internal {
  padding-top: 36px;
  background-color: #F2F2F2;
  &__wrapper {
    padding-top: 54px;
  }
  &__title {
    font-size: 64px;
    line-height: 80px;
    font-weight: 600;
    padding-bottom: 16px;
  }
  &__big-img {
    width: 100%;
    min-height: 545px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 37px;
    border-radius: 5px 5px 0px 0px;
  }
  &__area {
    p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 10px;
    }
    em {
      font-size: 16px;
      line-height: 26px;
      background-color: #ACCD8C;
      font-weight: 400;
      font-style: normal;
      display: inline-block;
      margin-top: 23px;
      padding: 2px 13px;
      margin-bottom: 28px;
    }
  }
  &__date {
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    display: block;
    margin-top: 50px;
  }
  &__wrap {
    margin-top: 50px;
  }
  &__list {
    display: flex;
    justify-content: center;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0 14px;
  }
  &__link {
    color: #F45050;
    text-decoration: underline;
    &:hover {
      color: #F45050;
    }
    &:focus {
      color: #F45050;
    }
  }
  &__down {
    margin-top: 75px;
    padding-bottom: 50px;
  }
  &__caption {
    font-size: 30px;
    line-height: 38px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}