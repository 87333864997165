body,
input,
textarea,
button,
select,
p,
a,
li,
span,
cite {
  font-weight: 400;
  font-size: 16px;
  color: #222222; }

body,
input,
textarea,
button,
select,
p,
a,
li,
span,
h1,
h2,
h3,
h4,
cite {
  font-family: 'Source Sans Pro', sans-serif; }

blockquote {
  font-size: 24px;
  line-height: 39px;
  font-weight: 300;
  font-style: italic;
  padding-left: 50px;
  padding-right: 100px;
  margin: 27px 0;
  display: block;
  position: relative; }
  blockquote::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    width: 16px;
    height: 16px;
    background-image: url(../img/commas.svg);
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat; }

.bread-crumbs__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0; }

.bread-crumbs__item {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: 12px;
  position: relative; }
  .bread-crumbs__item::before {
    content: "/";
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 14px;
    line-height: 18px;
    color: #fff; }
  .bread-crumbs__item:last-child::before {
    display: none; }
  .bread-crumbs__item--black::before {
    color: #222222; }

.bread-crumbs__link {
  color: #fff;
  font-size: 14px;
  line-height: 18px; }
  .bread-crumbs__link:hover {
    color: #fff;
    text-decoration: none; }
  .bread-crumbs__link:focus {
    color: #fff;
    text-decoration: none; }
  .bread-crumbs__link--black {
    color: #222222; }
    .bread-crumbs__link--black:hover {
      color: #222222; }
    .bread-crumbs__link--black:focus {
      color: #222222; }

.bread-crumbs__text {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline; }
  .bread-crumbs__text--black {
    color: #222222; }

.wrap-main {
  padding-top: 80px; }

.gray-bg {
  background-color: #F2F2F2;
  padding-top: 80px; }

.caption {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px; }

.text {
  line-height: 26px;
  margin-bottom: 10px; }

.block-btn__btn {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  background-color: #F45050;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
     -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  padding: 19px 0 20px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .block-btn__btn:hover {
    background-color: #D53838; }

.block-btn a:hover {
  color: inherit;
  text-decoration: none; }

.block-btn a:focus {
  color: inherit;
  text-decoration: none; }

.banner .block-btn {
  width: 170px;
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end; }
  .banner .block-btn__btn {
    background-color: #75AC40; }
    .banner .block-btn__btn:hover {
      background-color: #669836; }

.banner--right-content .banner__block-bg {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: right; }

.banner--right-content .banner__text-block {
  padding-right: 4px; }

.banner--right-content .block-btn__btn {
  background-color: #f45050; }
  .banner--right-content .block-btn__btn:hover {
    background-color: #d53838; }

.banner--right-content .block-name {
  right: auto;
  left: 40px; }

.banner--white-text {
  color: #fff; }
  .banner--white-text .banner__text-block {
    color: #fff; }

.banner__block-bg {
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  background-repeat: no-repeat;
  padding: 28px 40px 40px;
  min-height: 515px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative; }

.banner__title {
  font-size: 48px;
  line-height: 60px;
  font-weight: 400;
  max-width: 515px;
  margin-bottom: 0; }

.banner__text-block {
  font-size: 18px;
  line-height: 29px;
  display: block;
  width: 100%;
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
  margin-top: -64px; }

.block-name {
  width: 167px;
  border: 1px solid #fff;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  position: absolute;
  right: 33px;
  top: 40px;
  padding: 6px 0 7px; }
  .block-name__text-btn {
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    text-transform: uppercase;
    display: block;
    text-align: center;
    letter-spacing: 0.6px; }

.block-form {
  background-color: #75AC40;
  padding: 40px; }
  .block-form__title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff; }
  .block-form__text {
    margin-bottom: 24px;
    color: #fff; }
  .block-form__block {
    width: 100%; }
  .block-form__field {
    width: 100%;
    font-size: 14px;
    line-height: 23px;
    padding: 0 19px;
    margin-bottom: 10px;
    height: 60px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .block-form__field--four {
      height: 0;
      margin-bottom: 0; }
    .block-form__field--five {
      height: 0;
      margin-bottom: 0; }
    .block-form__field--six {
      height: 0;
      margin-bottom: 0; }
    .block-form__field--seven {
      height: 0;
      margin-bottom: 0; }
    .block-form__field--open {
      height: 60px;
      margin-bottom: 10px; }
  .block-form__textarea {
    width: 100%;
    font-size: 14px;
    line-height: 23px;
    padding: 18px 19px;
    margin-bottom: 15px;
    min-height: 110px;
    resize: none;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .block-form__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .block-form__wrap .block-form__field {
      width: 48.5%; }
  .block-form__policy {
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
    display: block;
    color: #fff;
    margin-bottom: 0; }
    .block-form__policy a {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
      color: #fff; }
      .block-form__policy a:hover {
        color: #fff;
        text-decoration: underline; }
      .block-form__policy a:focus {
        color: #fff;
        text-decoration: underline; }
  .block-form .block-btn {
    width: 170px;
    margin-top: 18px; }
    .block-form .block-btn__btn {
      background-color: #494949; }
      .block-form .block-btn__btn:hover {
        background-color: #d53838; }
      .block-form .block-btn__btn:focus {
        background-color: #d53838; }
  .block-form--contacts {
    background-color: #E2E3E1; }
    .block-form--contacts .block-form__title {
      color: #222; }
    .block-form--contacts .block-form__text {
      color: #222; }
    .block-form--contacts .block-form__policy {
      color: #4F4F4F; }
      .block-form--contacts .block-form__policy a {
        color: #4F4F4F; }
        .block-form--contacts .block-form__policy a:hover {
          color: #4F4F4F; }
        .block-form--contacts .block-form__policy a:focus {
          color: #4F4F4F; }
    .block-form--contacts .block-btn {
      width: 170px;
      margin-top: 18px; }
      .block-form--contacts .block-btn__btn {
        background-color: #F45050; }
        .block-form--contacts .block-btn__btn:hover {
          background-color: #d53838; }
        .block-form--contacts .block-btn__btn:focus {
          background-color: #d53838; }

.pagination {
  margin-top: 7px;
  padding-bottom: 83px; }
  .pagination__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0;
    margin: 0; }
  .pagination__item {
    padding: 0;
    margin: 0;
    list-style: none; }
  .pagination__arrow {
    font-size: 16px;
    line-height: 26px;
    color: #F45050;
    text-decoration: underline;
    margin: 0 9px; }
    .pagination__arrow:hover {
      color: #F45050;
      text-decoration: underline; }
    .pagination__arrow:focus {
      color: #F45050;
      text-decoration: underline; }
  .pagination__link {
    font-size: 16px;
    line-height: 26px;
    display: block;
    width: 27px;
    height: 27px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center; }
    .pagination__link:hover {
      background-color: #E2E3E1;
      color: #F45050;
      text-decoration: none; }
    .pagination__link:focus {
      background-color: #E2E3E1;
      color: #F45050;
      text-decoration: none; }

.header-up {
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  z-index: 12;
  background-color: #fff; }
  .header-up__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .header-up__text {
    font-size: 14px;
    line-height: 18px;
    margin-right: 12px; }
  .header-up__link {
    font-size: 18px;
    line-height: 23px; }
    .header-up__link:hover {
      color: inherit;
      text-decoration: none; }
    .header-up__link:focus {
      color: inherit;
      text-decoration: none; }

.private-office {
  background-color: #F2F2F2;
  padding: 11px 28px 13px 20px; }
  .private-office__link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .private-office__link:hover {
      text-decoration: none; }
    .private-office__link:focus {
      text-decoration: none; }
  .private-office__icon {
    display: block;
    width: 24px;
    height: 16px;
    background-image: url(../img/key.svg);
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 9px;
    margin-top: 1px; }
  .private-office__text {
    font-size: 16px;
    line-height: 20px; }

.header-page {
  padding: 7px 0 0; }
  .header-page__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 83px; }

.block-logo {
  margin-bottom: 0; }
  .block-logo__img {
    width: 149.89px;
    height: 76px; }

.nav {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .nav__list {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 0;
    margin: 0; }
  .nav__sub-list {
    position: absolute;
    top: 100%;
    left: -30px;
    padding: 0;
    margin: 0;
    padding: 27px 29px 23px;
    z-index: 30;
    background-color: #fff;
    width: 273px;
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .nav__item {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 22px;
    margin-left: 5.5%;
    position: relative; }
    .nav__item::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background-color: #75AC40;
      -webkit-border-radius: 2px;
         -moz-border-radius: 2px;
              border-radius: 2px;
      opacity: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .nav__item:hover::before {
      opacity: 1; }
    .nav__item:hover .nav__sub-list {
      opacity: 1;
      z-index: 30; }
    .nav__item--active::before {
      opacity: 1; }
  .nav__sub-item {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px; }
    .nav__sub-item:last-child {
      margin-bottom: 0; }
    .nav__sub-item a {
      font-size: 16px;
      line-height: 26px; }
  .nav__link {
    font-size: 18px;
    line-height: 23px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .nav__link:hover {
      color: #F45050;
      text-decoration: none; }
    .nav__link:focus {
      color: #F45050;
      text-decoration: none; }

.header-mob {
  display: none; }

.first-screen {
  position: relative;
  margin-top: -1px; }
  .first-screen__dots {
    position: absolute;
    top: 82px;
    left: 0;
    z-index: 5; }
    .first-screen__dots ul {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
         -moz-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      padding-left: 17px; }
    .first-screen__dots li {
      color: #fff;
      list-style: none;
      outline: inherit;
      margin-left: 0;
      margin-right: 19px; }
    .first-screen__dots button {
      font-size: 0;
      line-height: 0;
      width: 11px;
      height: 11px;
      -webkit-border-radius: 100px;
         -moz-border-radius: 100px;
              border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.3);
      outline: inherit;
      border: 2px solid transparent;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .first-screen__dots button:hover {
        background-color: rgba(255, 255, 255, 0.6);
        -webkit-transform: scale(1.5);
           -moz-transform: scale(1.5);
            -ms-transform: scale(1.5);
             -o-transform: scale(1.5);
                transform: scale(1.5); }
    .first-screen__dots .slick-active button {
      border: 2px solid #75AC40;
      background-color: rgba(255, 255, 255, 0);
      -webkit-transform: scale(1.2);
         -moz-transform: scale(1.2);
          -ms-transform: scale(1.2);
           -o-transform: scale(1.2);
              transform: scale(1.2); }
  .first-screen__slide {
    width: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover; }
  .first-screen__block {
    min-height: -webkit-calc(100vh - 135px);
    min-height: -moz-calc(100vh - 135px);
    min-height: calc(100vh - 135px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .first-screen__title {
    font-size: 64px;
    line-height: 80px;
    font-weight: 600;
    color: #fff;
    max-width: 750px;
    margin-bottom: 36px; }
  .first-screen__subtitle {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    display: block;
    margin-bottom: 66px; }
  .first-screen .block-btn {
    width: 170px;
    margin-bottom: 25px; }
    .first-screen .block-btn__btn {
      background-color: #75AC40; }
      .first-screen .block-btn__btn:hover {
        background-color: #669836; }
  .first-screen__arrows {
    position: absolute;
    bottom: 73px;
    left: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .first-screen__arrows .slick-arrow {
      font-size: 0;
      line-height: 0;
      width: 60px;
      height: 60px;
      -webkit-border-radius: 100px;
         -moz-border-radius: 100px;
              border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
      position: relative;
      outline: inherit;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s; }
      .first-screen__arrows .slick-arrow::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 19px;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -9.5px;
        background-repeat: no-repeat;
        background-image: url(../img/arrow-next.svg);
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain; }
      .first-screen__arrows .slick-arrow:hover {
        background-color: rgba(255, 255, 255, 0.6);
        -webkit-transform: scale(1.2);
           -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
             -o-transform: scale(1.2);
                transform: scale(1.2); }
        .first-screen__arrows .slick-arrow:hover::before {
          -webkit-transform: scale(0.86);
             -moz-transform: scale(0.86);
              -ms-transform: scale(0.86);
               -o-transform: scale(0.86);
                  transform: scale(0.86); }
    .first-screen__arrows .slick-prev {
      margin-right: 18px; }
      .first-screen__arrows .slick-prev::before {
        margin-left: -7px;
        background-image: url(../img/arrow-prev.svg); }

.main-one__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 22px; }

.main-one__caption {
  font-size: 30px;
  line-height: 38px; }

.main-one__link {
  font-size: 18px;
  line-height: 23px;
  color: #FF4B4B;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .main-one__link:hover {
    color: #D53838; }
  .main-one__link:focus {
    color: #D53838; }

.one-slider {
  position: relative; }
  .one-slider .block-name {
    right: auto;
    left: 40px; }
  .one-slider__slide {
    height: 515px;
    padding: 26px 40px 40px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    position: relative; }
  .one-slider__block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-align-content: space-between;
        -ms-flex-line-pack: justify;
            align-content: space-between;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100%; }
  .one-slider__title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;
    width: 100%;
    text-align: right; }
  .one-slider__subtitle {
    display: block;
    max-width: 380px;
    text-align: right;
    width: 100%;
    margin-top: -23px; }
  .one-slider__price {
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    width: 100%;
    margin-top: 20px; }
    .one-slider__price b {
      font-size: 72px;
      line-height: 91px;
      color: #F45050;
      font-weight: 400;
      margin-right: 2px; }
  .one-slider__wrap {
    width: 100%;
    margin-bottom: 20px; }
  .one-slider__sm-price {
    font-size: 18px;
    line-height: 23px;
    display: block;
    text-align: right; }
    .one-slider__sm-price b {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
      margin-right: 2px; }
  .one-slider .block-btn {
    width: 170px; }
    .one-slider .block-btn__btn {
      background-color: #75AC40; }
      .one-slider .block-btn__btn:hover {
        background-color: #669836; }
  .one-slider .slick-arrow {
    position: absolute;
    font-size: 0;
    line-height: 0;
    width: 60px;
    height: 60px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    background-color: #fff;
    -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    z-index: 5;
    top: 50%;
    margin-top: -30px;
    outline: inherit;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .one-slider .slick-arrow::before {
      content: "";
      position: absolute;
      -webkit-background-size: contain;
         -moz-background-size: contain;
              background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 19px;
      top: 50%;
      left: 50%;
      margin-top: -9.5px;
      margin-left: -6px; }
    .one-slider .slick-arrow:hover {
      -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
         -moz-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
              box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25); }
  .one-slider .slick-prev {
    left: -20px; }
    .one-slider .slick-prev::before {
      background-image: url(../img/prev-black.svg); }
  .one-slider .slick-next {
    right: -20px; }
    .one-slider .slick-next::before {
      background-image: url(../img/next-black.svg); }

.main-two {
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  background-repeat: no-repeat;
  margin-top: 80px; }
  .main-two__wrapper {
    padding: 29px 35px 40px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    min-height: 430px; }
  .main-two__block {
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start;
    width: 100%; }
    .main-two__block:nth-child(3) {
      margin-top: -34px; }
  .main-two__title {
    font-size: 48px;
    line-height: 60px;
    max-width: 500px;
    color: #fff;
    width: 100%;
    margin-bottom: 0; }
  .main-two__subtitle {
    font-size: 18px;
    line-height: 29px;
    color: #fff;
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: block; }
  .main-two .block-btn {
    -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
            align-self: flex-end;
    width: 170px;
    margin-left: 5px;
    margin-bottom: 2px; }
    .main-two .block-btn__btn {
      background-color: #75ac40; }
      .main-two .block-btn__btn:hover {
        background-color: #669836; }
  .main-two .block-name {
    right: -3px;
    top: 24px; }

.main-three__wrapper {
  margin-top: 79px; }

.main-four {
  background-color: #F2F2F2;
  margin-top: 80px;
  padding-top: 81px;
  padding-bottom: 50px; }

.news-block__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

.news-block__link {
  color: #F45050;
  text-decoration: underline;
  font-size: 18px;
  line-height: 23px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .news-block__link:hover {
    color: #D53838;
    text-decoration: underline; }
  .news-block__link:focus {
    color: #D53838;
    text-decoration: underline; }

.page-footer {
  background-color: #98BE73;
  padding-top: 20px;
  border-bottom: 1px solid #8DAE6D;
  padding-bottom: 17px; }
  .page-footer__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .page-footer__right-block {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding-left: 109px; }
  .page-footer__policy {
    color: #222222;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    text-decoration: underline;
    display: block;
    margin-top: 35px; }
    .page-footer__policy:hover {
      color: #222222;
      text-decoration: underline; }
    .page-footer__policy:focus {
      color: #222222;
      text-decoration: underline; }
  .page-footer__nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 10px; }
  .page-footer__block:last-child {
    margin-left: 39px; }
  .page-footer__list {
    padding: 0;
    margin: 0; }
  .page-footer__item {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 3px; }
  .page-footer__caption {
    color: #222222;
    display: block;
    margin-bottom: 2px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .page-footer__caption:hover {
      color: #F2F2F2;
      text-decoration: none; }
    .page-footer__caption:focus {
      color: #F2F2F2;
      text-decoration: none; }
  .page-footer__link {
    color: #4F4F4F;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400; }
    .page-footer__link:hover {
      color: #F2F2F2;
      text-decoration: none; }
    .page-footer__link:focus {
      color: #F2F2F2;
      text-decoration: none; }

.row-down {
  background-color: #98BE73; }
  .row-down__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
       -moz-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    padding: 15px 0 20px; }
  .row-down__sm-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #222222; }
  .row-down__block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 60px; }
  .row-down__text {
    color: #4F4F4F;
    font-size: 14px;
    line-height: 18px; }
  .row-down__link {
    color: #4F4F4F;
    font-size: 24px;
    line-height: 30px;
    margin-left: 14px; }
    .row-down__link:hover {
      color: #4F4F4F;
      text-decoration: none; }
    .row-down__link:focus {
      color: #4F4F4F;
      text-decoration: none; }

.developers__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }
  .developers__link:hover {
    text-decoration: none; }

.developers__text {
  color: #222222;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  display: block;
  width: 100%;
  padding-left: 2px; }

.developers__logo {
  width: 100px;
  margin-left: 14px;
  height: 24px; }

.block-title {
  background-repeat: no-repeat;
  -webkit-background-size: contain;
     -moz-background-size: contain;
          background-size: contain;
  padding: 37px 0 98px;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover; }
  .block-title__title {
    font-size: 64px;
    line-height: 80px;
    color: #fff;
    font-weight: 600;
    margin-top: 53px; }
  .block-title__sub-title {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    padding-left: 6px;
    margin-top: 18px;
    display: block; }

.repairs-one {
  padding-bottom: 40px; }
  .repairs-one__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .repairs-one__left-block {
    width: 65%; }
  .repairs-one__right-block {
    width: 31.5%; }

.table {
  max-width: 600px;
  margin-top: 50px; }
  .table__up {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid #75AC40;
    padding: 0 45px 2px 30px; }
  .table__left {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
       -moz-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    min-width: 145px; }
  .table__center {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    text-align: left;
    padding-left: 27px; }
  .table__right {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
       -moz-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    text-align: right;
    min-width: 111px; }
  .table__caption {
    font-size: 18px;
    line-height: 29px; }
  .table__wrap {
    margin-top: 17px; }
  .table__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 51px 0 27px;
    margin-bottom: 10px; }

.check-button {
  margin-top: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px; }
  .check-button__text {
    font-size: 14px;
    line-height: 23px;
    display: block;
    color: #fff; }
  .check-button__block {
    margin-bottom: 0; }
  .check-button__wrap {
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #F4F4F4;
    -webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 40px;
    height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer; }
  .check-button__check {
    display: none; }
  .check-button__circle {
    background-color: #F45050;
    -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    left: 0; }

.check-button__check:checked + .check-button__wrap .check-button__circle {
  left: 20px; }

.repairs-two {
  padding-top: 42px; }
  .repairs-two__caption {
    font-size: 30px;
    line-height: 38px;
    margin-top: 81px;
    padding-left: 2px;
    letter-spacing: 0.1px; }
  .repairs-two__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 13px; }
  .repairs-two__block {
    max-width: 370px; }
  .repairs-two__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 8px; }
  .repairs-two__block-img {
    padding-left: 6px;
    margin-bottom: 0;
    padding-top: 7px;
    margin-right: 24px; }
  .repairs-two__img {
    width: 41px;
    height: 40px; }
  .repairs-two__sm-caption {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 29px;
    max-width: 220px; }

.repairs-three {
  margin-bottom: 77px;
  background-image: url(../img/repairs-three-bg.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  margin-top: 55px;
  padding-top: 38px;
  padding-bottom: 37px; }
  .repairs-three__title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;
    color: #fff;
    padding-left: 1px;
    margin-bottom: 25px; }
  .repairs-three__sub-title {
    font-size: 18px;
    line-height: 29px;
    color: #fff;
    padding-left: 1px; }
  .repairs-three__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 32px; }
  .repairs-three__field {
    width: 291px;
    font-size: 14px;
    line-height: 23px;
    padding: 0 19px;
    margin-bottom: 10px;
    height: 60px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-right: 31px; }
  .repairs-three .block-btn {
    width: 170px; }
  .repairs-three__policy {
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-top: 10px; }
    .repairs-three__policy a {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline; }
      .repairs-three__policy a:hover {
        color: inherit;
        text-decoration: underline; }
      .repairs-three__policy a:focus {
        color: inherit;
        text-decoration: underline; }

.big-article {
  background-color: #fff;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px; }
  .big-article:hover {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); }
  .big-article__img-wrap {
    width: 100%;
    height: 265px;
    background-image: url(../img/news-img.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    -webkit-border-radius: 5px 5px 0 0;
       -moz-border-radius: 5px 5px 0 0;
            border-radius: 5px 5px 0 0; }
    .big-article__img-wrap a {
      height: 100%;
      width: 100%;
      display: block; }
  .big-article__block-img {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 5px 5px 0 0;
       -moz-border-radius: 5px 5px 0 0;
            border-radius: 5px 5px 0 0;
    margin-bottom: 0;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover; }
  .big-article__block-text {
    padding: 20px 30px 23px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .big-article__title {
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 11px; }
  .big-article__text {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0;
    font-weight: 400; }
  .big-article__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
       -moz-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline; }
  .big-article .block-btn {
    width: 170px;
    margin-top: 35px; }
  .big-article__date {
    font-size: 14px;
    line-height: 23px;
    color: #75ac40;
    font-weight: 600; }
  .big-article a:hover {
    color: inherit;
    text-decoration: none; }
  .big-article a:focus {
    color: inherit;
    text-decoration: none; }

.sm-article {
  background-color: #fff;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .sm-article:hover {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); }
  .sm-article__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .sm-article__left-block {
    width: 47.4%; }
  .sm-article__img-wrap {
    background-image: url(../img/news-img.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    width: 100%;
    height: 170px;
    background-position: center;
    -webkit-border-radius: 5px 0 0 5px;
       -moz-border-radius: 5px 0 0 5px;
            border-radius: 5px 0 0 5px; }
    .sm-article__img-wrap a {
      display: block;
      width: 100%;
      height: 100%; }
  .sm-article__block-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    margin-bottom: 0;
    -webkit-border-radius: 5px 0 0 5px;
       -moz-border-radius: 5px 0 0 5px;
            border-radius: 5px 0 0 5px; }
  .sm-article__right-block {
    width: 50%; }
  .sm-article__block-text {
    padding: 11px 5px 13px 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%; }
  .sm-article__title {
    font-size: 14px;
    line-height: 23px;
    font-weight: 600; }
  .sm-article__text {
    font-size: 14px;
    line-height: 23px;
    font-weight: 400; }
  .sm-article a:hover {
    color: inherit;
    text-decoration: none; }
  .sm-article a:focus {
    color: inherit;
    text-decoration: none; }
  .sm-article__date {
    font-size: 14px;
    line-height: 23px;
    color: #75ac40;
    font-weight: 600; }

.internal {
  padding-top: 36px;
  background-color: #F2F2F2; }
  .internal__wrapper {
    padding-top: 54px; }
  .internal__title {
    font-size: 64px;
    line-height: 80px;
    font-weight: 600;
    padding-bottom: 16px; }
  .internal__big-img {
    width: 100%;
    min-height: 545px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    margin-bottom: 37px;
    -webkit-border-radius: 5px 5px 0px 0px;
       -moz-border-radius: 5px 5px 0px 0px;
            border-radius: 5px 5px 0px 0px; }
  .internal__area p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px; }
  .internal__area em {
    font-size: 16px;
    line-height: 26px;
    background-color: #ACCD8C;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    margin-top: 23px;
    padding: 2px 13px;
    margin-bottom: 28px; }
  .internal__date {
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    display: block;
    margin-top: 50px; }
  .internal__wrap {
    margin-top: 50px; }
  .internal__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .internal__item {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0 14px; }
  .internal__link {
    color: #F45050;
    text-decoration: underline; }
    .internal__link:hover {
      color: #F45050; }
    .internal__link:focus {
      color: #F45050; }
  .internal__down {
    margin-top: 75px;
    padding-bottom: 50px; }
  .internal__caption {
    font-size: 30px;
    line-height: 38px;
    font-weight: 400;
    margin-bottom: 20px; }

.television__block-text {
  padding-left: 2px; }

.television__text {
  font-size: 16px;
  line-height: 26px;
  display: block;
  margin-bottom: 10px; }
  .television__text a {
    color: #F45050;
    font-size: 16px;
    line-height: 26px;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .television__text a:hover {
      color: #d53838;
      text-decoration: underline; }
    .television__text a:focus {
      color: #d53838;
      text-decoration: underline; }

.television__list {
  padding: 0;
  margin: 0;
  margin-top: 10px; }

.television__item {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 15px;
  position: relative; }
  .television__item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    width: 7px;
    height: 7px;
    background-color: #F45050;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px; }

.television__button {
  border-bottom: 2px solid #75AC40; }

.television em {
  font-size: 16px;
  line-height: 26px;
  font-style: normal;
  background-color: #ACCD8C;
  display: inline-block;
  margin-top: 13px;
  padding: 1px 18px 3px 10px;
  margin-bottom: 28px; }

.television__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px; }

.television__icon {
  width: 16px;
  height: 20px;
  display: block;
  background-image: url(../img/file-document-outline.svg);
  background-repeat: no-repeat;
  -webkit-background-size: contain;
     -moz-background-size: contain;
          background-size: contain;
  margin-right: 15px; }

.television__receipts {
  display: block;
  font-size: 16px;
  line-height: 26px;
  position: relative; }

.disable {
  opacity: 0.4; }

.rates {
  margin-top: 10px;
  padding-bottom: 60px; }
  .rates--individuals {
    margin-top: 40px; }
  .rates__caption {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 22px;
    margin-bottom: 25px; }
  .rates__block {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 27px 30px 30px;
    min-height: 456px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;
    position: relative; }
    .rates__block .block-btn {
      position: relative;
      overflow: hidden;
      z-index: 4; }
      .rates__block .block-btn__btn--active {
        position: absolute;
        right: 110%;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.6s;
        -o-transition: all 0.6s;
        -moz-transition: all 0.6s;
        transition: all 0.6s; }
        .rates__block .block-btn__btn--active--open {
          right: 0; }
  .rates__block-text {
    margin-bottom: 20px; }
  .rates__name {
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    text-transform: uppercase;
    display: block;
    letter-spacing: 0.5px; }
  .rates__caption-block {
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    display: block;
    margin-top: 13px;
    margin-bottom: 25px; }
  .rates__text {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300; }
  .rates__price-block {
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 7px; }
  .rates__price {
    font-size: 24px;
    line-height: 30px;
    word-wrap: 1px; }
    .rates__price b {
      font-size: 48px;
      line-height: 60px;
      color: #F45050;
      font-weight: 400;
      display: inline-block; }
  .rates__quantity {
    font-size: 16px;
    line-height: 20px;
    display: block;
    margin-bottom: 2px; }
    .rates__quantity span {
      font-size: 24px;
      line-height: 30px; }
  .rates__link {
    color: #F45050;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 3; }
    .rates__link:hover {
      color: #d53838; }
    .rates__link:focus {
      color: #d53838; }
  .rates .block-btn {
    margin-top: 25px; }
    .rates .block-btn__btn {
      background-color: #75AC40;
      padding: 18px 0; }
      .rates .block-btn__btn:hover {
        background-color: #669836; }
  .rates__wrap-hint {
    margin: 38px 0 13px; }
  .rates__hint {
    font-size: 16px;
    line-height: 26px;
    display: block;
    margin-bottom: 8px; }
  .rates__row {
    margin-bottom: 58px; }
  .rates__item {
    position: relative; }

.rates-form {
  position: absolute;
  top: 0;
  -webkit-transition: left 0.6s ease-in-out, opacity 0.6s ease-in-out, z-index 1s;
  -o-transition: left 0.6s ease-in-out, opacity 0.6s ease-in-out, z-index 1s;
  -moz-transition: left 0.6s ease-in-out, opacity 0.6s ease-in-out, z-index 1s;
  transition: left 0.6s ease-in-out, opacity 0.6s ease-in-out, z-index 1s;
  bottom: 0;
  width: 353px;
  background-color: #fff;
  padding: 27px 30px 30px 33px;
  z-index: 2;
  -webkit-box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.15);
          box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0 5px 5px 0;
     -moz-border-radius: 0 5px 5px 0;
          border-radius: 0 5px 5px 0;
  left: 0;
  z-index: 3;
  opacity: 0; }
  .rates-form--open {
    left: 99%;
    opacity: 1;
    z-index: 5; }
  .rates-form__close {
    position: absolute;
    display: block;
    right: 10px;
    top: 10px;
    width: 14px;
    height: 14px;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    cursor: pointer;
    z-index: 10; }
  .rates-form__field {
    width: 290px;
    border: 1px solid #C4C4C4;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    font-size: 14px;
    line-height: 23px;
    color: #4F4F4F;
    padding: 17px 19px;
    margin-bottom: 11px; }
  .rates-form__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .rates-form__wrap .rates-form__field {
      width: 48%; }
  .rates-form__policy {
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;
    display: block;
    margin-top: 16px; }
    .rates-form__policy a {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
      color: #4F4F4F; }
      .rates-form__policy a:hover {
        color: #4F4F4F; }
      .rates-form__policy a:focus {
        color: #4F4F4F; }

.additional-packages {
  padding-bottom: 58px; }
  .additional-packages__caption {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 0; }
  .additional-packages .table {
    margin-top: 21px;
    max-width: 558px; }
    .additional-packages .table__row .table__right {
      text-align: left;
      padding-left: 5px; }

.block-tarifs:last-child .rates-form {
  left: auto;
  right: 0;
  -webkit-transition: right .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
  -o-transition: right .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
  -moz-transition: right .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
  transition: right .6s ease-in-out, opacity .6s ease-in-out, z-index 1s; }
  .block-tarifs:last-child .rates-form--open {
    right: 99%; }

.info__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 27px;
  max-width: 750px;
  margin-bottom: 47px; }

.info__sm-block {
  width: 185px; }

.info__big-number {
  font-size: 72px;
  line-height: 72px;
  color: #F45050;
  font-weight: 700;
  display: block; }

.info__description {
  line-height: 16px; }

.block-wrap {
  margin-top: 53px; }
  .block-wrap__block {
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px; }
  .block-wrap__block-img {
    min-height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    margin-bottom: 32px;
    -webkit-border-radius: 5px 5px 0 0;
       -moz-border-radius: 5px 5px 0 0;
            border-radius: 5px 5px 0 0; }
  .block-wrap__caption {
    margin-bottom: 14px; }
  .block-wrap__text {
    line-height: 26px; }
  .block-wrap__link {
    font-size: 18px;
    line-height: 23px;
    color: #F45050;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: block;
    margin-top: 28px; }
    .block-wrap__link:hover {
      color: #d53838; }
    .block-wrap__link:focus {
      color: #d53838; }

.license {
  margin-top: 75px;
  padding-bottom: 63px; }
  .license__wrapper {
    padding-top: 55px; }
  .license__block {
    width: 100%;
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
  .license__block-img {
    width: 100%;
    min-height: 340px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover; }

.pay__container {
  margin-bottom: 75px; }

.pay__block-img {
  margin-bottom: 29px; }

.pay__list {
  padding: 0;
  margin: 0;
  margin-bottom: 22px; }

.pay__item {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  position: relative;
  padding-left: 16px;
  margin-bottom: 10px; }
  .pay__item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    width: 7px;
    height: 7px;
    background-color: #F45050;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px; }

.pay__text a {
  color: #f45050;
  text-decoration: underline;
  margin-left: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .pay__text a:hover {
    color: #d53838; }
  .pay__text a:focus {
    color: #d53838; }

.contacts {
  padding-bottom: 84px; }
  .contacts__list {
    padding: 0;
    margin: 0; }
  .contacts__item {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 25px;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    margin-bottom: 11px; }
    .contacts__item::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 23px;
      background-repeat: no-repeat;
      -webkit-background-size: contain;
         -moz-background-size: contain;
              background-size: contain;
      top: 50%;
      margin-top: -11.5px;
      background-position: center; }
    .contacts__item--target::before {
      background-image: url(../img/location.svg); }
    .contacts__item--mail::before {
      background-image: url(../img/contact.svg); }
    .contacts__item--tel::before {
      background-image: url(../img/phone.svg); }

.map {
  max-width: 700px;
  height: 375px;
  margin-top: 25px; }

.my-modal__dialog {
  margin-top: 157px;
  max-width: 1170px; }

.my-modal__content {
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  padding: 26px 30px 30px;
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15); }

.my-modal__close {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 14px;
  height: 14px;
  display: block;
  background-image: url(../img/close.svg);
  background-repeat: no-repeat;
  -webkit-background-size: contain;
     -moz-background-size: contain;
          background-size: contain;
  z-index: 20;
  cursor: pointer;
  background-color: transparent; }

.channels__text {
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 13px; }

.channels__caption {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600; }

.channels__container {
  position: relative;
  padding-top: 7px; }

.channels__wrap {
  height: 490px;
  overflow: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 0 0 -6.5px; }

.channels__block {
  padding: 0 6.2px;
  margin-bottom: 10px; }

.channels__img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  width: 162px;
  height: 90px; }

.scrollbar-outer > .scroll-element {
  background-color: #F4F4F4;
  -webkit-box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
          box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
     -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
      -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
       -o-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0); }

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
  background-color: #E0E0E0;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
     -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  max-height: 70px;
  cursor: pointer; }

.my-modal-two .my-modal__close {
  top: 25px; }

.my-modal-two .my-modal__dialog {
  max-width: 370px; }

.my-modal-two .my-modal__name {
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 12px; }

.my-modal-two .my-modal__caption {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  margin-bottom: 15px; }

.my-modal-two .my-modal__text {
  font-size: 16px;
  line-height: 26px; }

.my-modal-two .my-modal__block-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%; }

@media (max-width: 1400px) {
  .first-screen__arrows {
    bottom: 40px; } }

@media (max-width: 1200px) {
  .nav__item {
    margin-left: 3.3%; }
  .one-slider .block-name {
    top: 15px;
    left: 15px; }
  .one-slider__slide {
    position: relative; }
    .one-slider__slide::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
  .one-slider__block {
    position: relative;
    z-index: 10;
    padding-top: 25px; }
  .one-slider__title {
    color: #fff; }
  .one-slider__subtitle {
    color: #fff; }
  .one-slider__price {
    color: #fff; }
  .one-slider__sm-price {
    color: #fff; }
  .one-slider .slick-prev {
    left: -30px; }
  .one-slider .slick-next {
    right: -30px; }
  .first-screen__arrows {
    bottom: 30px; }
  .license__block-img {
    background-position: center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain; } }

@media (max-width: 991px) {
  header {
    position: relative; }
  .header-up {
    position: relative;
    z-index: 20;
    background-color: #fff; }
    .header-up__left-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
         -moz-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
  .header-mob {
    display: block;
    position: relative; }
    .header-mob__wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      z-index: 30;
      padding: 5px 0; }
    .header-mob::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 20; }
  .burger {
    width: 20px;
    height: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer; }
    .burger__line {
      width: 100%;
      height: 2px;
      background-color: #000; }
  .header-wrap {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: -1200px;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
    .header-wrap--open {
      left: 0; }
    .header-wrap .container {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important; }
    .header-wrap .row {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important; }
    .header-wrap .col-xl-12 {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important; }
  .header-up {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2); }
    .header-up .container {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important; }
    .header-up .row {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: auto !important; }
    .header-up .col-xl-12 {
      margin: 0 !important;
      padding: 0 !important;
      position: static;
      width: 100% !important; }
    .header-up__wrapper {
      width: 100%; }
    .header-up__left-block {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      padding-left: 50px;
      width: 50%; }
    .header-up .private-office {
      padding-right: 50px; }
  .private-office {
    padding: 18px 28px 18px 20px; }
  .header-page {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-top: 0; }
    .header-page .block-logo {
      display: none; }
  .nav {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
       -moz-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; }
    .nav__list {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
    .nav__item {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 0;
      padding-left: 50px; }
      .nav__item:last-child {
        border: none; }
      .nav__item::before {
        display: none; }
      .nav__item--active a {
        color: #f45050; }
      .nav__item--dropdown a {
        position: relative;
        display: inline-block;
        padding-right: 30px; }
        .nav__item--dropdown a::after {
          content: "";
          position: absolute;
          right: 0;
          top: 8px;
          width: 9px;
          height: 9px;
          background-image: url(../img/arrow-down.svg);
          -webkit-background-size: contain;
             -moz-background-size: contain;
                  background-size: contain;
          background-repeat: no-repeat;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
      .nav__item--open .nav__sub-list {
        height: 108px; }
      .nav__item--open a::after {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg); }
    .nav__sub-list {
      position: static;
      opacity: 1;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none;
      padding: 0;
      max-height: 999999999999px;
      -webkit-transition: 0;
      -o-transition: 0;
      -moz-transition: 0;
      transition: 0;
      height: 0;
      overflow: hidden;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .nav__sub-item {
      padding-left: 15px;
      padding-top: 2px;
      padding-bottom: 2px; }
      .nav__sub-item a::after {
        display: none; }
  .block-form {
    margin-bottom: 30px; }
  .banner--right-content .banner__title {
    max-width: 455px; }
  .banner--right-content .banner__text-block {
    padding-left: 50%; }
  .banner--white-text .banner__title {
    max-width: 380px; }
  .banner--white-text .banner__text-block {
    padding-right: 50%; }
  .page-footer__right-block {
    padding-left: 60px; }
  .page-footer__nav {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .page-footer__block {
    width: 150px;
    margin-bottom: 15px; }
    .page-footer__block:last-child {
      margin-left: 0; }
  .row-down__wrapper {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .row-down__block {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0; }
  .row-down__text {
    width: 100%;
    display: block;
    text-align: right; }
  .row-down__link {
    display: block;
    width: 100%;
    text-align: right; }
  .row-down__sm-text {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    width: 100%;
    margin-top: 10px; }
  .television__block {
    margin-bottom: 30px; }
  .developers {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .block-tarifs {
    margin-bottom: 30px; }
    .block-tarifs:last-child .rates-form {
      left: 0;
      right: 0;
      top: 0;
      -webkit-transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
      -o-transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
      -moz-transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
      transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
      background-color: #fff;
      min-height: 450px;
      width: 100%; }
      .block-tarifs:last-child .rates-form--open {
        top: 100%;
        opacity: 1; }
  .rates-form {
    left: 0;
    right: 0;
    top: 0;
    -webkit-transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
    -o-transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
    -moz-transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
    transition: top .6s ease-in-out, opacity .6s ease-in-out, z-index 1s;
    background-color: #fff;
    min-height: 450px;
    width: 100%; }
    .rates-form--open {
      top: 100%;
      opacity: 1; }
    .rates-form__field {
      width: 100%; }
  .additional-packages {
    overflow: scroll; }
  .table {
    width: 500px; }
  .info__wrap {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .block-wrap__block {
    margin-bottom: 30px; }
  .internal__title {
    font-size: 30px;
    line-height: 40px; }
  .repairs-one .block-form {
    margin-top: 30px; } }

@media (max-width: 768px) {
  .first-screen__block {
    min-height: -webkit-calc(100vh - 86px);
    min-height: -moz-calc(100vh - 86px);
    min-height: calc(100vh - 86px); }
  .first-screen__dots {
    top: 32px; }
  .first-screen__title {
    font-size: 50px;
    line-height: 54px;
    margin-bottom: 15px; }
  .first-screen__subtitle {
    margin-bottom: 30px; }
  .main-two .block-name {
    top: 10px;
    right: 15px; }
  .main-two__wrapper {
    padding-top: 40px; }
  .sm-article__wrapper {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .sm-article__left-block {
    width: 100%; }
  .sm-article__right-block {
    width: 100%;
    padding: 15px; }
  .page-footer__wrapper {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .page-footer__left-block {
    width: 100%; }
  .page-footer__right-block {
    width: 100%;
    padding-left: 0;
    padding-top: 15px; }
  .page-footer__policy {
    text-align: center;
    margin-top: 15px; }
  .page-footer__block {
    width: 100%;
    text-align: center; }
  .block-logo__img {
    margin: 0 auto; }
  .row-down__wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .row-down__text {
    text-align: center; }
  .row-down__link {
    text-align: center;
    margin: 0; }
  .row-down__sm-text {
    text-align: center; }
  .block-title {
    padding-bottom: 60px; }
    .block-title__title {
      font-size: 44px;
      line-height: 50px;
      margin-top: 20px; }
  .map {
    margin-bottom: 30px; }
  .internal__big-img {
    min-height: 245px; }
  blockquote {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
    padding-left: 25px; }
  .repairs-two .banner__block-bg {
    padding-top: 70px; }
  .repairs-two .banner__title {
    font-size: 26px;
    line-height: 34px; }
  .repairs-three__wrap {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media (max-width: 560px) {
  .first-screen__title {
    font-size: 32px;
    line-height: 40px; }
  .header-up__wrapper {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .header-up__left-block {
    width: 100%;
    padding: 10px 0 10px 50px; }
  .header-up__right-block {
    width: 100%; }
  .one-slider__block {
    padding-top: 20px; }
  .one-slider__title {
    font-size: 26px;
    line-height: 30px; }
  .one-slider__price {
    margin-top: 0; }
    .one-slider__price b {
      font-size: 50px;
      line-height: 50px; }
  .one-slider .slick-arrow {
    top: auto;
    margin-top: auto;
    bottom: -30px; }
  .one-slider .slick-prev {
    left: 20%; }
  .one-slider .slick-next {
    right: 20%; }
  .main-two__title {
    font-size: 28px;
    line-height: 36px;
    margin-top: 30px; }
  .banner__block-bg {
    padding: 20px;
    padding-top: 40px;
    position: relative; }
  .banner--right-content .banner__block-bg {
    position: relative; }
    .banner--right-content .banner__block-bg::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
  .banner--right-content .banner__title {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    position: relative;
    z-index: 1; }
  .banner--right-content .banner__text-block {
    color: #fff;
    padding-left: 0;
    position: relative;
    z-index: 1; }
  .banner--white-text .banner__block-bg {
    position: relative; }
    .banner--white-text .banner__block-bg::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
  .banner--white-text .banner__title {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    position: relative;
    z-index: 1; }
  .banner--white-text .banner__text-block {
    color: #fff;
    padding-left: 0;
    position: relative;
    z-index: 1; }
  .banner .block-btn {
    position: relative;
    z-index: 1; }
  .banner--right-content .block-name {
    top: 10px;
    left: 10px; }
  .banner--white-text .block-name {
    top: 10px;
    right: 10px; }
  .big-article__wrap {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .big-article__date {
    margin-top: 15px; }
  .caption {
    font-size: 26px;
    line-height: 34px; }
  .bread-crumbs__list {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .internal__list {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .internal__item {
    width: 100%;
    text-align: center;
    margin-bottom: 5px; }
  .pay__img {
    width: 290px;
    height: 90px; }
  .repairs-one__wrap-table {
    overflow: scroll; } }
